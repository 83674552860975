import React, { useEffect } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { reCaptchaKey } from "../../../../components/Variables"
import { downloadReportView } from "../../../../components/Statuses/Statuses"

import Layout from "../../../../components/Layout/Layout"
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs"
import HeaderImage from "../../../../assets/img/new/tlo-serch-01.webp"
import HubSpotForm from "../../../../components/HubSpotForm"

import { devMode } from "../../../../components/Variables"

import "../../../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Download = props => {
  const id = props.params.id
  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage("de")
    downloadReportView(id)
  }, [id])

  return (
    <Layout
      seo={{
        title: "Ihr SEO-Auditbericht • Laden Sie das Ergebnis des Audits der Webseite kostenlos herunter • WeNet.pl",
        description:
          "Füllen Sie das Formular aus und laden Sie Ihren SEO-Audit-Bericht kostenlos herunter • finden Sie heraus, wie Sie Ihre Webseite verbessern können",
        hreflangs: {
          pl: "audyt/" + id + "/pobierz/",
          en: "en/audit/" + id + "/download/",
          de: "de/audit/" + id + "/herunterladen/",
        },
        robots: "noindex, nofollow",
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: "/audyt/" + id + "/pobierz",
                text: t("link-text-download-report"),
              },
            ]}
          />
        </div>
        <h1 className="title text-center">SEO-Audit-Bericht kostenlos herunterladen</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="dowload-text hubspot-success-hide" style={{ marginTop: "30px" }}>
                Nachdem Sie das untenstehende Formular ausgefüllt haben, senden wir Ihnen einen vollständigen und
                umfassenden SEO-Audit-Bericht Ihrer Webseite im PDF-Format an die angegebene E-Mail-Adresse. Der Bericht
                wird durch ausführliche Beschreibungen der geprüften Kriterien ergänzt, die Ihnen helfen, die
                Erwartungen der Suchmaschinen besser zu verstehen und Ihre Webseite zu optimieren.
              </p>
            </div>
            <div className="col-md-12">
              <div style={{ maxWidth: "535px", margin: "40px auto 0 auto" }}>
                <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                  <HubSpotForm
                    formId={
                      devMode === "true"
                        ? "48641c85-de04-4629-a317-6981ba3910a9"
                        : "4ece4da1-a58f-4b64-aa0a-961f198a1d97"
                    }
                    formType="download"
                    auditId={id}
                    userWWW={props?.location?.state?.userWWW ? props.location.state.userWWW : undefined}
                  />
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Download
